import React from 'react';

type IconProps = {
  fill?: string;
  secondaryfill?: string;
  strokewidth?: number;
  width?: string;
  height?: string;
};

const IconPropsDefaults = {
  fill: 'currentColor',
  secondaryfill: 'currentColor',
  strokewidth: 1,
  width: '1em',
  height: '1em',
};

export const UserVoiceIcon: React.FC<IconProps> = (props) => {
  const fill = props.fill || IconPropsDefaults.fill;
  const secondaryfill = props.secondaryfill || IconPropsDefaults.secondaryfill;
  const strokewidth = props.strokewidth || IconPropsDefaults.strokewidth;
  const width = props.width || IconPropsDefaults.width;
  const height = props.height || IconPropsDefaults.height;

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={fill}>
        <circle
          cx="7.5"
          cy="5"
          fill="none"
          r="2.25"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokewidth}
        />
        <path
          d="M12.341,6.591c.407-.407,.659-.97,.659-1.591,0-.621-.252-1.184-.659-1.591"
          fill="none"
          stroke={secondaryfill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokewidth}
        />
        <path
          d="M14.639,8.889c.995-.995,1.611-2.37,1.611-3.889s-.616-2.894-1.611-3.889"
          fill="none"
          stroke={secondaryfill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokewidth}
        />
        <path
          d="M11.54,14.817c.837-.291,1.266-1.257,.866-2.048-.906-1.791-2.761-3.02-4.906-3.02s-4,1.228-4.906,3.02c-.4,.791,.028,1.757,.866,2.048,1.031,.358,2.408,.683,4.04,.683s3.009-.325,4.04-.683Z"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokewidth}
        />
      </g>
    </svg>
  );
};

export const SprayCanIcon: React.FC<IconProps> = (props) => {
  const fill = props.fill || IconPropsDefaults.fill;
  const secondaryfill = props.secondaryfill || IconPropsDefaults.secondaryfill;
  const strokewidth = props.strokewidth || IconPropsDefaults.strokewidth;
  const width = props.width || IconPropsDefaults.width;
  const height = props.height || IconPropsDefaults.height;

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={fill}>
        <polyline
          fill="none"
          points="13.25 13.25 8.75 13.25 8.75 8.75 13.25 8.75"
          stroke={secondaryfill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokewidth}
        />
        <circle
          cx="13.25"
          cy="2.75"
          fill={secondaryfill}
          r=".75"
          stroke="none"
        />
        <circle
          cx="15.75"
          cy="1.25"
          fill={secondaryfill}
          r=".75"
          stroke="none"
        />
        <circle
          cx="15.75"
          cy="4.25"
          fill={secondaryfill}
          r=".75"
          stroke="none"
        />
        <path
          d="M6.75,4.75h3.5c1.656,0,3,1.344,3,3v7.5c0,.552-.448,1-1,1H4.75c-.552,0-1-.448-1-1V7.75c0-1.656,1.344-3,3-3Z"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokewidth}
        />
        <path
          d="M6.75,4.75V1.75c0-.552,.448-1,1-1h1.5c.552,0,1,.448,1,1v3"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokewidth}
        />
      </g>
    </svg>
  );
};

export const UserPlusIcon: React.FC<IconProps> = (props) => {
  const fill = props.fill || IconPropsDefaults.fill;
  const secondaryfill = props.secondaryfill || IconPropsDefaults.secondaryfill;
  const strokewidth = props.strokewidth || IconPropsDefaults.strokewidth;
  const width = props.width || IconPropsDefaults.width;
  const height = props.height || IconPropsDefaults.height;

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={fill}>
        <circle
          cx="9"
          cy="4.5"
          fill="none"
          r="2.75"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokewidth}
        />
        <line
          fill="none"
          stroke={secondaryfill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokewidth}
          x1="17.25"
          x2="12.25"
          y1="14.75"
          y2="14.75"
        />
        <line
          fill="none"
          stroke={secondaryfill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokewidth}
          x1="14.75"
          x2="14.75"
          y1="12.25"
          y2="17.25"
        />
        <path
          d="M12.633,10.932c-1.024-.738-2.274-1.182-3.633-1.182-2.551,0-4.739,1.53-5.709,3.72-.365,.825,.087,1.774,.947,2.045,1.225,.386,2.846,.734,4.762,.734,.422,0,.824-.023,1.217-.053"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokewidth}
        />
      </g>
    </svg>
  );
};

export const BandaidIcon: React.FC<IconProps> = (props) => {
  const fill = props.fill || IconPropsDefaults.fill;
  const secondaryfill = props.secondaryfill || IconPropsDefaults.secondaryfill;
  const strokewidth = props.strokewidth || IconPropsDefaults.strokewidth;
  const width = props.width || IconPropsDefaults.width;
  const height = props.height || IconPropsDefaults.height;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 24 24"
    >
      <g
        className="nc-icon-wrapper"
        fill={fill}
        strokeLinecap="square"
        strokeLinejoin="miter"
        transform="translate(0.5 0.5)"
        strokeMiterlimit="10"
      >
        <line
          fill="none"
          stroke={fill}
          strokeWidth="1"
          x1="12"
          y1="20"
          x2="4"
          y2="12"
        ></line>
        <line
          fill="none"
          stroke={fill}
          strokeWidth="1"
          x1="12"
          y1="4"
          x2="20"
          y2="12"
        ></line>
        <circle
          data-color="color-2"
          data-stroke="none"
          fill={fill}
          cx="12"
          cy="9"
          r="1"
          strokeLinejoin="miter"
          stroke="none"
        ></circle>
        <circle
          data-color="color-2"
          data-stroke="none"
          fill={fill}
          cx="15"
          cy="12"
          r="1"
          strokeLinejoin="miter"
          stroke="none"
        ></circle>
        <circle
          data-color="color-2"
          data-stroke="none"
          fill={fill}
          cx="9"
          cy="12"
          r="1"
          strokeLinejoin="miter"
          stroke="none"
        ></circle>
        <circle
          data-color="color-2"
          data-stroke="none"
          fill={fill}
          cx="12"
          cy="15"
          r="1"
          strokeLinejoin="miter"
          stroke="none"
        ></circle>
        <path
          fill="none"
          stroke={fill}
          strokeWidth={strokewidth}
          d="M22.414,9.586 c0.781-0.781,0.781-2.047,0-2.828l-5.172-5.172c-0.781-0.781-2.047-0.781-2.828,0L1.586,14.414c-0.781,0.781-0.781,2.047,0,2.828 l5.172,5.172c0.781,0.781,2.047,0.781,2.828,0L22.414,9.586z"
        ></path>
      </g>
    </svg>
  );
};
