type DateFormatOptions = {
  year?: 'numeric' | '2-digit';
  month?: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long';
  day?: 'numeric' | '2-digit';
  weekday?: 'narrow' | 'short' | 'long'; // Include weekday option
  hour?: 'numeric' | '2-digit';
  minute?: 'numeric' | '2-digit';
  second?: 'numeric' | '2-digit';
  timeZoneName?: 'short' | 'long';
  hour12?: boolean; // Use 12-hour format with AM/PM
};

/**
 * Formats a single date according to the specified options.
 * @param {string} isoString - The ISO date string to format.
 * @param {DateFormatOptions} options - The format options.
 * @returns {string} - The formatted date string.
 */
export const formatDate = (isoString: string, options: DateFormatOptions = {}): string => {
  const date = new Date(isoString);

  // Use only the provided options if any, otherwise default to showing year, month, and day
  const formatOptions: DateFormatOptions = Object.keys(options).length > 0 ? options : {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  return new Intl.DateTimeFormat('en-US', formatOptions).format(date);
};

/**
 * Formats a date range according to the specified options.
 * Displays the month twice only if the range spans multiple months.
 * @param {string} startIso - The start ISO date string.
 * @param {string} endIso - The end ISO date string.
 * @param {DateFormatOptions} options - The format options.
 * @returns {string} - The formatted date range string.
 */

export const formatDateRange = (startIso: string, endIso: string, options: DateFormatOptions = {}): string => {
  const start = new Date(startIso);
  const end = new Date(endIso);

  const sameMonthAndYear = start.getMonth() === end.getMonth() && start.getFullYear() === end.getFullYear();

  const startDateOptions: DateFormatOptions = {
    month: 'short', // Always show the month for the start date
    day: 'numeric',
    ...options,
  };

  const endDateOptions: DateFormatOptions = sameMonthAndYear
    ? { day: 'numeric' } // Only show the day if the month and year are the same
    : { month: 'short', day: 'numeric', ...options }; // Show both month and day if different

  const formattedStartDate = new Intl.DateTimeFormat('en-US', startDateOptions).format(start);
  const formattedEndDate = new Intl.DateTimeFormat('en-US', endDateOptions).format(end);

  return `${formattedStartDate} - ${formattedEndDate}`;
};
