import { useCalendarEventsQuery } from './useCalendarEventsQuery';
import { useResourcesQuery } from './useResourcesQuery';
import { useAvailablePaintersByDayQuery } from './useAvailablePaintersByDayQuery';
import { useWeatherDaysQuery } from './useWeatherDaysQuery';
import { CraftworkerOpening } from '@/types';
import { ConstructReactQueryConfig } from '@/utils/constructReactQuery';

type StaffingCalendarDataProps = {
  startDate: string;
  endDate: string;
};

export const useStaffingCalendarData = (
  { startDate, endDate }: StaffingCalendarDataProps,
  config?: ConstructReactQueryConfig,
) => {
  const { data: craftworkerOpenings } =
    useCalendarEventsQuery<CraftworkerOpening>(
      {
        start: startDate,
        end: endDate,
        options: {
          craftworker_openings: true,
        },
      },
      config,
    );
  const { data: weatherDays } = useWeatherDaysQuery(
    {
      start: startDate,
      end: endDate,
    },
    config,
  );
  const { data: availablePaintersByDay } = useAvailablePaintersByDayQuery(
    {
      start: startDate,
      end: endDate,
    },
    config,
  );

  const { data: calendarEvents } = useCalendarEventsQuery(
    {
      start: startDate,
      end: endDate,
      options: {
        cabinet_shop_openings: true,
        collated: true,
        headquarters_openings: true,
        project_openings: true,
        shifts: true,
        timeoff_openings: true,
      },
    },
    config,
  );

  const { data: resources } = useResourcesQuery(
    {
      start: startDate,
      end: endDate,
    },
    config,
  );

  return {
    craftworkerOpenings: craftworkerOpenings || [],
    weatherDays: weatherDays || [],
    availablePaintersByDay: availablePaintersByDay || [],
    calendarEvents: calendarEvents || [],
    resources: resources || [],
  };
};
