import { ColCellContentArg } from '@fullcalendar/resource';
import React from 'react';

const groupOrderName = (value: number) => {
  switch (value) {
    case 1:
      return 'Projects';
    case 2:
      return 'Cabinet Shops';
    case 3:
      return 'Craftwork HQ';
    case 4:
      return 'PTO';
    default:
      return 'Other';
  }
};

export const GroupLabel: React.FC<{ args: ColCellContentArg }> = ({ args }) => {
  return (
    <div className="-ml-2 flex items-center">
      <div className="truncate font-base-bold capitalize">
        {groupOrderName(args.groupValue)}
      </div>
    </div>
  );
};
