import React from 'react';

type ProjectResourceLabelProps = {
  resourceContent: {
    resource: {
      extendedProps: {
        account: { name: string };
        resourceType: string;
        customTitle: string;
        url: string;
        projectType: string;
        totalEventDays: number;
      };
    };
  };
};

export const ProjectResourceLabel: React.FC<ProjectResourceLabelProps> = ({
  resourceContent,
}) => {
  const {
    title,
    extendedProps: {
      url,
      projectType,
      totalEventDays,
      resourceType,
      customTitle,
      account: { name },
    },
  } = resourceContent.resource;

  const indicatorColor = projectType ? `bg-${projectType}` : `bg-gray-200`;

  return (
    <div className="flex w-full gap-2">
      <div className={`h-10 w-1 rounded ${indicatorColor}`}></div>
      <div className="w-full overflow-hidden">
        <a
          href={url}
          className="flex flex-col gap-2 md:flex-row md:items-center"
        >
          <span className="truncate font-base-bold">{resourceType == 'project' ? name : title}</span>
        </a>

        {resourceType === 'Project' && (
          <div className="text-sm text-gray-600">
            <span className="font-base-medium">{totalEventDays}</span> days
            {customTitle && (
              <span className="font-base-medium"> - {customTitle}</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
