import React, { useState, useEffect, useRef } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Avatar, AvatarGroup } from '@/ui/Avatars';
import { clsxm } from '@/utils/clsxm';
import { BandaidIcon, SprayCanIcon, UserVoiceIcon } from '@/ui/Icons';

import type {
  CalendarEvent,
  Craftworker,
  Shift,
  StaffingCounts,
  Timesheet,
} from '@/types/index';
import { isBefore } from 'date-fns';
import { useCsrfToken } from '@/hooks/useCsrfToken';

const indicatorClasses =
  'w-5 h-5 bg-gray-100 text-plum rounded-full grid place-items-center leading-none';

type EventHeaderProps = {
  craftworkers: any[];
  staffing: StaffingCounts;
  showCount: boolean;
  hideBadges?: boolean;
  countClasses?: string | null;
  reworkReason?: string | null;
};

const EventHeader: React.FC<EventHeaderProps> = ({
  craftworkers,
  staffing,
  showCount,
  hideBadges,
  countClasses,
  reworkReason,
}) => {
  const committed = staffing.committed ?? 0;
  const assigned = staffing.assigned ?? 0;

  const overStaffed = committed < assigned;
  const understaffed = committed > 0 && assigned < committed;

  const englishSpeakers = craftworkers.filter((cw) =>
    cw.languages.includes('en'),
  );
  const englishSpeakersString = craftworkers
    .filter((cw) => cw.languages.includes('en'))
    .map((cw, i, arr) => {
      if (i === 0) return cw.firstName;
      if (i === arr.length - 1) return ` and ${cw.firstName}`;
      return `, ${cw.firstName}`;
    })
    .join('');

  const sprayers = craftworkers.filter((cw) => cw.level.includes(3));
  const sprayersString = craftworkers
    .filter((cw) => cw.level.includes(3))
    .map((cw, i, arr) => {
      if (i === 0) return cw.firstName;
      if (i === arr.length - 1) return ` and ${cw.firstName}`;
      return `, ${cw.firstName}`;
    })
    .join('');

  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex gap-1">
        {!hideBadges && (
          <>
            <span
              className={clsxm(
                indicatorClasses,
                englishSpeakers.length < 1 && 'bg-red-100 text-warning',
              )}
              data-controller="tooltip"
              data-tippy-content={
                englishSpeakers.length > 0
                  ? `${englishSpeakersString} speak${englishSpeakers.length === 1 ? 's' : ''} english`
                  : 'No english speakers staffed'
              }
            >
              <UserVoiceIcon />
            </span>
            {sprayers.length > 0 && (
              <span
                className={indicatorClasses}
                data-controller="tooltip"
                data-tippy-content={sprayersString + ' can spray'}
              >
                <SprayCanIcon />
              </span>
            )}
            {reworkReason && (
              <span
                className={clsxm(
                  indicatorClasses,
                  'bg-yellow-200 text-yellow-600',
                )}
                data-controller="tooltip"
                data-tippy-content={reworkReason}
              >
                <BandaidIcon />
              </span>
            )}
          </>
        )}
      </div>
      <span
        className={clsxm(
          'ml-auto flex h-5 min-w-5 items-center justify-center rounded bg-green-100 px-1 font-base-bold text-xs text-green-700',
          understaffed && !showCount && 'bg-yellow-200 text-caution',
          overStaffed && !showCount && 'bg-red-100 text-warning',
          showCount && 'bg-gray-100 text-gray-500',
          countClasses,
        )}
        data-controller="tooltip"
        data-tippy-content={`Staffed ${assigned} of ${committed} committed`}
      >
        {assigned} {!showCount && `of ${committed}`}
      </span>
    </div>
  );
};

const EventContent: React.FC<{ craftworkers: Craftworker[] }> = ({
  craftworkers,
}) =>
  craftworkers.length > 0 ? (
    <AvatarGroup
      users={craftworkers}
      className="max-w-max flex-grow items-center"
    />
  ) : (
    <div className="flex flex-grow flex-col items-center justify-center font-base-bold">
      <span>No Shifts Scheduled</span>
    </div>
  );

const Timesheet: React.FC<{
  timesheet: Timesheet;
  craftworker: Craftworker;
}> = ({ timesheet, craftworker }) => {
  const start = new Date(timesheet.startedAt).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
  const end = new Date(timesheet.endedAt).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  return (
    <li>
      <a
        href={`/timesheets/${timesheet.timesheetId}?modal=true&dark_mode=true`}
        data-turbo-frame="modal"
        className="flex gap-2 p-2"
      >
        <Avatar user={craftworker} />
        <div className="w-full">
          <span className="flex items-center justify-between gap-2 truncate font-base-bold leading-none">
            {craftworker.firstName}
            <span className="flex gap-1">
              {craftworker.languages.includes('en') && <UserVoiceIcon />}
              {craftworker.level.includes(3) && <SprayCanIcon />}
            </span>
          </span>
          <span
            className={`${timesheet.approved || timesheet.inProgress ? 'text-gray-500' : 'text-caution'} block tabular-nums`}
          >
            {start} - {timesheet.inProgress ? 'TBD' : end}
          </span>
        </div>
      </a>
    </li>
  );
};

const Shift: React.FC<{ shift: Shift }> = ({ shift }) => {
  const start = new Date(shift.start).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
  const end = new Date(shift.end).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  return (
    <li>
      <a
        href={`/shifts/${shift.shiftId}?modal=true&dark_mode=true`}
        data-turbo-frame="modal"
        className="flex gap-2 p-2"
      >
        <Avatar user={shift.craftworker} />
        <div className="w-full">
          <span className="flex items-center justify-between gap-2 truncate font-base-bold leading-none">
            {shift.craftworker.firstName}
            <span className="flex gap-1">
              {shift.craftworker.languages.includes('en') && <UserVoiceIcon />}
              {shift.craftworker.level.includes(3) && <SprayCanIcon />}
            </span>
          </span>
          <span className="block text-gray-500">
            {start} - {end}
          </span>
        </div>
      </a>
    </li>
  );
};

const useDeletePlannedShiftsMutation = () => {
  const queryClient = useQueryClient();
  const token = useCsrfToken();

  return useMutation({
    mutationFn: async (shiftIds: string[]) => {
      const response = await fetch('/api/v1/shifts/bulk_destroy', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/vnd.turbo-stream.html', // Request Turbo Stream response
          'X-CSRF-Token': token,
        },
        body: JSON.stringify({ ids: shiftIds }),
      });

      if (!response.ok) {
        throw new Error('Failed to delete shifts');
      }
      const turboStreamHTML = await response.text();

      // Use Turbo to process the Turbo Stream HTML
      Turbo.renderStreamMessage(turboStreamHTML);

      return turboStreamHTML;
    },
    onSuccess: () => {
      // invalidate every query that starts with /api/v1/calendar_events
      // see: https://tanstack.com/query/latest/docs/framework/react/guides/query-invalidation#query-matching-with-invalidatequeries
      queryClient.invalidateQueries({ queryKey: ['/api/v1/calendar_events'] });
    },
    onError: (error) => {
      console.error('Error deleting shifts:', error);
    },
  });
};

const EventDropdown: React.FC<{
  event: CalendarEvent;
  craftworkers: Craftworker[];
  isPTO?: boolean;
}> = ({ event, craftworkers }) => {
  const { extendedProps, start, end, startStr, endStr } = event;
  const {
    locationId,
    locationShortAddress,
    accountOwnerName,
    shifts,
    staffing,
    eventId,
    reworkReasonId,
  } = extendedProps;
  const previousEvent = isBefore(start, new Date().setHours(0, 0, 0, 0));

  const levels = {
    LEVEL1: 'level_1',
    LEVEL2: 'level_2',
    LEVEL3: 'level_3',
  } as const;

  const levelOneCount = craftworkers.filter(
    (cw) => cw.level === levels.LEVEL1,
  ).length;
  const levelTwoCount = craftworkers.filter((cw) =>
    cw.level.includes(levels.LEVEL2),
  ).length;

  const levelThreeCount = craftworkers.filter((cw) =>
    cw.level.includes(levels.LEVEL3),
  ).length;

  const plannedShifts = shifts.filter(
    (shift) => !shift.timesheets || shift.timesheets.length === 0,
  );

  // A shift can have many timesheets. We need all of them in a flat array of objects containing the timesheet and the craftworker
  const inProgressWork = shifts.flatMap((shift) =>
    shift.timesheets
      .filter((timesheet) => {
        return timesheet.inProgress;
      })
      .map((timesheet) => ({
        timesheet: timesheet,
        craftworker: shift.craftworker,
      })),
  );

  const completedWork = shifts.flatMap((shift) =>
    shift.timesheets
      .filter((timesheet) => !timesheet.inProgress)
      .map((timesheet) => ({
        timesheet: timesheet,
        craftworker: shift.craftworker,
      })),
  );

  const deletePlannedShiftsMutation = useDeletePlannedShiftsMutation();

  const handleClearPlannedShifts = () => {
    const plannedShiftIds = plannedShifts.map((shift) => shift.shiftId);
    deletePlannedShiftsMutation.mutate(plannedShiftIds);
  };

  const assigned = staffing.assigned ?? 0;
  const committed = staffing.committed ?? 0;

  const newShiftParams = new URLSearchParams({
    modal: 'true',
    dark_mode: 'true',
    location_id: locationId,
    starts_at: startStr,
    ends_at: endStr,
    event_id: eventId ?? '',
    rework_reason_id: reworkReasonId ?? '',
  });

  const newShiftUrl = `/shifts/new?${newShiftParams.toString()}`;

  return (
    <div className="absolute left-0 right-0 top-[calc(100%-5px)] space-y-4 rounded-b-lg bg-plum p-2 shadow-dark">
      <div className="flex flex-col items-center gap-1 leading-none">
        <span className="font-base-bold text-md">{accountOwnerName}</span>
        <span>{locationShortAddress}</span>
        <span>
          {assigned} / {committed}
        </span>
      </div>
      {craftworkers.length > 0 && (
        <>
          <div className="flex justify-center gap-6 font-base-medium">
            <span>L1: {levelOneCount}</span>
            <span>L2: {levelTwoCount}</span>
            <span>L3: {levelThreeCount}</span>
          </div>
          {plannedShifts.length > 0 && (
            <div>
              <div className="mb-1 flex justify-between">
                <span className="block font-base-bold">Planned:</span>
                <span>
                  <button
                    onClick={handleClearPlannedShifts}
                    className="text-gray-300 hover:text-white"
                  >
                    Clear
                  </button>
                </span>
              </div>
              <ul className="max-h-[300px] divide-y overflow-y-auto rounded border bg-white text-plum">
                {plannedShifts.map((shift, i) => (
                  <Shift shift={shift} key={i} />
                ))}
              </ul>
            </div>
          )}
          {inProgressWork.length > 0 && (
            <div>
              <span className="mb-1 block font-base-bold">In Progress:</span>
              <ul className="max-h-[300px] divide-y overflow-y-auto rounded border bg-white text-plum">
                {inProgressWork.map((work, i) => (
                  <Timesheet
                    craftworker={work.craftworker}
                    timesheet={work.timesheet}
                    key={i}
                  />
                ))}
              </ul>
            </div>
          )}
          {completedWork.length > 0 && (
            <div>
              <span className="mb-1 block font-base-medium">Completed:</span>
              <ul className="max-h-[300px] divide-y overflow-y-auto rounded border bg-white text-plum">
                {completedWork.map((work, i) => (
                  <Timesheet
                    craftworker={work.craftworker}
                    timesheet={work.timesheet}
                    key={i}
                  />
                ))}
              </ul>
            </div>
          )}
        </>
      )}

      {!start && (
        <div className="btn -mt-2 w-full justify-center bg-caution text-center leading-none text-white">
          Missing Start Time
        </div>
      )}
      {!end && (
        <div className="btn -mt-2 w-full justify-center bg-caution text-center leading-none text-white">
          Missing End Time
        </div>
      )}

      {!previousEvent && start && end && (
        <div>
          <a
            className="btn btn-white -mt-2 w-full justify-center text-center leading-none"
            href={newShiftUrl}
            data-turbo-frame="modal"
          >
            Add Painter
          </a>
        </div>
      )}
    </div>
  );
};

type CollatedEvent = CalendarEvent & {
  extendedProps: {
    accountOwnerName: string;
    eventId?: string | null;
    locationId: string;
    locationShortAddress: string;
    projectId?: string | null;
    reworkReasonId?: string | null;
    reworkReasonTitle?: string | null;
    shifts: Shift[];
    staffing: StaffingCounts;
  };
};

type CollatedEventProps = {
  eventInfo: { event: CollatedEvent };
};

const CollatedEvent: React.FC<CollatedEventProps> = ({ eventInfo }) => {
  const { event } = eventInfo;

  const { extendedProps, title } = event;
  const { staffing } = extendedProps;
  const targetRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [showDetails, setShowDetails] = useState(false);
  const craftworkers = extendedProps.shifts.map((shift) => shift.craftworker);

  const assigned = staffing?.assigned ?? 0;
  const committed = staffing?.committed ?? 0;
  const noStaff = assigned === 0 && committed > 0;

  const isPTO = title === 'PTO' || title === 'PTO 2';
  const isUPTO = title === 'UPTO';
  const isProjectEvent = !isPTO && !isUPTO;
  const isCraftworkFacilityEvent = title.includes('Craftwork');

  const handleTargetClick: React.MouseEventHandler<HTMLDivElement> = (
    event,
  ) => {
    const element = event.target as HTMLElement;
    const td = element.closest('td');

    if (showDetails) {
      setShowDetails(false);
      if (td) td.style.zIndex = 'unset';
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      setShowDetails(true);
      if (td) td.style.zIndex = '999';
    }
  };

  const handleWindowClick = (event: MouseEvent) => {
    const element = event.target as HTMLElement;
    const target = targetRef.current;
    const wrapper = wrapperRef.current;

    if (
      !target ||
      !wrapper ||
      target === element ||
      (element && target.contains(element)) ||
      (element && wrapper.contains(element))
    )
      return;

    const td = target.closest('td');

    if (td && element && !td.contains(element)) {
      td.style.zIndex = 'unset';
    }

    setShowDetails(false);
  };

  useEffect(() => {
    if (showDetails) {
      window.addEventListener('click', handleWindowClick);
    }
    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  }, [showDetails]);

  return (
    <div
      ref={wrapperRef}
      className={clsxm(
        'flex min-h-[68px] flex-col rounded-md bg-white p-2 hover:outline hover:outline-plum/30',
        noStaff && 'text-caution',
        showDetails && 'border-0 bg-plum text-white shadow-md',
      )}
    >
      <div
        ref={targetRef}
        className="flex flex-grow flex-col items-center"
        onClick={handleTargetClick}
      >
        <EventHeader
          craftworkers={craftworkers}
          staffing={staffing}
          showCount={isCraftworkFacilityEvent || !isProjectEvent}
          countClasses={clsxm(
            isPTO && 'bg-notification text-white',
            isUPTO && 'bg-caution text-white',
          )}
          hideBadges={!isProjectEvent}
          reworkReason={extendedProps.reworkReasonTitle}
        />
        <EventContent craftworkers={craftworkers} />
      </div>

      {showDetails && (
        <EventDropdown
          event={eventInfo.event}
          craftworkers={craftworkers}
          isPTO={!isProjectEvent}
        />
      )}
    </div>
  );
};

export default CollatedEvent;
