import { Resource } from '@/types';
import React from 'react';

type ProjectResourceLabelProps = {
  resource: Resource;
};

export const ResourceLabel: React.FC<ProjectResourceLabelProps> = ({
  resource,
}) => {
  const {
    title,
    url,
    projectType,
    totalEventDays,
    resourceType,
    customTitle,
    account: { name },
  } = resource;

  // Determine the color indicator based on the project type
  const indicatorColor = projectType ? `bg-${projectType}` : `bg-gray-200`;

  return (
    <div className="flex w-full gap-2 p-2 border-r border-b">
      <div className={`h-10 w-1 rounded ${indicatorColor}`}></div>
      <div className="w-full overflow-hidden">
        <a
          href={url}
          className="flex flex-col gap-2 md:flex-row md:items-center"
        >
          <span className="truncate font-base-bold">
            {resourceType === 'Project' ? name : title}
          </span>
        </a>

        {resourceType === 'Project' && (
          <div className="text-sm text-gray-600">
            <span className="font-base-medium">{totalEventDays}</span> days
            {customTitle && (
              <span className="font-base-medium"> - {customTitle}</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

