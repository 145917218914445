import { CraftworkerOpening } from '@/types';
import {
  constructReactQuery,
  ConstructReactQueryConfig,
} from '@/utils/constructReactQuery';

type CraftworkerOpeningsQueryProps = {
  start: string;
  end: string;
};

export const useCraftworkerOpeningsQuery = (
  { start, end }: CraftworkerOpeningsQueryProps,
  config?: ConstructReactQueryConfig,
) => {
  const url = `/api/v1/calendar_events?craftworker_openings=true&start=${start}&end=${end}`;
  return constructReactQuery<CraftworkerOpening[]>(url, config);
};
