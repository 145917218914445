import React, { useRef, useEffect, useState } from 'react';

import { EventMountArg } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import { QueryClientProvider } from '@tanstack/react-query';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';

import { queryClient } from '@/application';
import CollatedEvent from './CollatedEvent';
import { DayHeader } from './DayHeader';
import { useStaffingCalendarData } from '@/hooks/useStaffingCalendarData';
import {
  CalendarViewState,
  getViewSizeForFullCalendarView,
  useCalendarSettings,
} from '@/hooks/useCalendarSettings';
import { GroupLabel } from './GroupLabel';
import { MoreLink } from './MoreLink';
import { ProjectResourceLabel } from './ProjectResourceLabel';
import { ProjectResourceHeader } from './ProjectResourceHeader';
import type {
  FullCalendarDateInfo,
  StaffingCalendarProps,
  FullCalendarToolbarOptions,
} from '@/types/index';
import {
  DAYS_OF_THE_WEEK,
  getDateBoundsForViewSize,
} from '@/hooks/useCalendarSettings';

const CalendarSettingsViewOptions = {
  ResourceTimelineDay: 'resourceTimelineDay',
  ResourceTimelineWeek: 'resourceTimelineWeek',
} as const;

const toolbarOptions: FullCalendarToolbarOptions = {
  resourceTimelineDay: {
    left: 'prev today next',
    center: 'title',
    right: 'projectCalendarButton',
  },
  resourceTimelineWeek: {
    left: 'today toggleWeekendsButton',
    center: 'prev title next',
    right: 'publishShiftsButton projectCalendarButton',
  },
};

const buttonsConfig = {
  toggleWeekendsButton: 'Show Weekends',
  today: 'Today',
  resourceTimelineWeek: 'Week',
  resourceTimelineDay: 'Day',
};

const defaultViewState: CalendarViewState = {
  view: 'resourceTimelineWeek',
  viewSize: 'week',
  showWeekends: false,
  ...getDateBoundsForViewSize('week', new Date().toISOString()),
};

const StaffingCalendarDisplay: React.FC<StaffingCalendarProps> = ({
  apiKey,
}) => {
  const calendarRef = useRef<FullCalendar>();
  const [isMobile, setIsMobile] = useState(false);

  const { calendarSettings, setCalendarSettings, toggleWeekends } =
    useCalendarSettings({
      calendarName: 'staffing_calendar',
      defaultViewState,
    });

  const handleViewDidMount = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  };

  /**
   * When the calendar date range changes, we need to update the query date range
   * and refetch the data to match the new date range.
   */
  const handleCalendarDatesChanged = (dateInfo: FullCalendarDateInfo) => {
    setCalendarSettings((prevSettings: CalendarViewState) => ({
      ...prevSettings,
      view: dateInfo.view.type,
      viewSize: getViewSizeForFullCalendarView(dateInfo.view.type),
      startDate: dateInfo.startStr,
      endDate: dateInfo.endStr,
    }));
  };

  const {
    craftworkerOpenings,
    weatherDays,
    availablePaintersByDay,
    calendarEvents,
    resources,
  } = useStaffingCalendarData({
    ...getDateBoundsForViewSize(
      calendarSettings.viewSize,
      calendarSettings.startDate,
    ),
  });

  const handleEventDidMount = (arg: EventMountArg) => {
    const element = arg.el;
    // remove default element href applied by FullCalendar
    element.removeAttribute('href');
  };

  const customButtonsConfig = {
    toggleWeekendsButton: {
      text: calendarSettings.showWeekends ? 'Hide Weekends' : 'Show Weekends',
      click: toggleWeekends,
    },
    projectCalendarButton: {
      text: 'Projects',
      click: () => (window.location.href = '/project_calendars'),
    },
    publishShiftsButton: {
      text: 'Publish Shifts',
      click: () => {
        const { startDate, endDate } = getDateBoundsForViewSize(
          calendarSettings.viewSize,
          calendarSettings.startDate,
        );
        console.log('Publishing shifts for:', startDate, endDate);
        // We use a dynamically created anchor element to simulate a Turbo Frame request,
        // allowing us to load the content into a modal without navigating away from the page.
        const anchor = document.createElement('a');
        anchor.href = `/shifts/in_range?modal=true&starts_at=${startDate}&ends_at=${endDate}`;
        anchor.dataset.turboFrame = 'modal';

        document.body.appendChild(anchor);
        anchor.click();

        document.body.removeChild(anchor);
      },
    },
  };

  useEffect(() => {
    if (!calendarRef.current) return;
    const calendarApi = calendarRef.current.getApi();

    const handleResize = () => {
      if (window.innerWidth < 768 && !isMobile) {
        calendarSettings.view !==
          CalendarSettingsViewOptions.ResourceTimelineDay &&
          calendarApi.changeView(
            CalendarSettingsViewOptions.ResourceTimelineDay,
          );
        setIsMobile(true);
      } else if (window.innerWidth >= 768 && isMobile) {
        calendarApi.changeView(
          CalendarSettingsViewOptions.ResourceTimelineWeek,
        );
        setIsMobile(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [calendarSettings.view, isMobile]);

  // making sure we have a date range set before displaying the calendar
  if (!calendarSettings) {
    return <div>Loading...</div>;
  }

  return (
    <FullCalendar
      ref={calendarRef}
      buttonText={buttonsConfig}
      customButtons={customButtonsConfig}
      datesSet={(dateInfo: FullCalendarDateInfo) =>
        handleCalendarDatesChanged(dateInfo)
      }
      editable={false}
      eventResourceEditable={false}
      eventClassNames={' mb-[-10px] hover:!opacity-100'}
      eventContent={(eventInfo) => <CollatedEvent eventInfo={eventInfo} />}
      eventDisplay="block"
      eventTextColor={'#5A4C62'}
      eventDidMount={(arg) => handleEventDidMount(arg)}
      events={calendarEvents}
      eventMaxStack={4}
      eventStartEditable={false}
      expandRows={true}
      firstDay={DAYS_OF_THE_WEEK.MONDAY}
      headerToolbar={toolbarOptions[calendarSettings.view]}
      height={'100%'}
      initialDate={calendarSettings.startDate}
      initialView={CalendarSettingsViewOptions.ResourceTimelineWeek}
      moreLinkClassNames={'hover:opacity-100'}
      moreLinkContent={(arg) => <MoreLink args={arg} />}
      plugins={[resourceTimelinePlugin, interactionPlugin]}
      refetchResourcesOnNavigate={true}
      resources={resources}
      resourceAreaWidth={isMobile ? '50%' : '20%'}
      resourceGroupField={'resourceGroupOrder'}
      resourceGroupLabelContent={(resourceContent) => (
        <GroupLabel args={resourceContent} />
      )}
      resourceAreaHeaderContent={(_resourceContent) => (
        <ProjectResourceHeader />
      )}
      resourceLabelContent={(resourceContent) => (
        <ProjectResourceLabel resourceContent={resourceContent} />
      )}
      resourceGroupLaneClassNames={'bg-plum'}
      resourceOrder={'resourceGroupOrder'}
      slotDuration={{ days: 1 }}
      slotLabelContent={(props) => (
        <DayHeader
          {...props}
          weatherDays={weatherDays}
          craftworkerOpenings={craftworkerOpenings}
          dailyPainterAvailability={availablePaintersByDay}
        />
      )}
      slotLabelInterval={{ days: 1 }}
      slotLabelFormat={{
        weekday: 'short',
        day: 'numeric',
        omitCommas: true,
      }}
      schedulerLicenseKey={apiKey}
      titleFormat={{ month: 'long', day: 'numeric' }}
      viewClassNames={'mx-2 mb-2 border rounded-t-md rounded-b overflow-hidden'}
      viewDidMount={handleViewDidMount}
      views={{
        resourceTimelineDay: {
          resourceAreaHeaderContent: 'Project',
          dayHeaders: false,
          titleFormat: { weekday: 'long', month: 'long', day: 'numeric' },
        },
        resourceTimelineWeek: {
          resourceAreaHeaderContent: 'Project',
          titleFormat: { month: 'long', day: 'numeric' },
        },
      }}
      weekends={calendarSettings.showWeekends}
    />
  );
};

export const StaffingCalendar: React.FC<{ apiKey: string }> = ({ apiKey }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <StaffingCalendarDisplay apiKey={apiKey} />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
};
