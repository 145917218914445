import { DailyPainterAvailability } from '@/types';
import {
  constructReactQuery,
  ConstructReactQueryConfig,
} from '@/utils/constructReactQuery';

type AvailablePaintersByDayQueryProps = {
  start: string;
  end: string;
};

export const useAvailablePaintersByDayQuery = (
  { start, end }: AvailablePaintersByDayQueryProps,
  config?: ConstructReactQueryConfig,
) => {
  const url = `/api/v1/craftworkers/painter_count?start=${start}&end=${end}`;
  return constructReactQuery<DailyPainterAvailability[]>(url, config);
};
