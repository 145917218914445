import { Resource } from '@/types';
import {
  constructReactQuery,
  ConstructReactQueryConfig,
} from '@/utils/constructReactQuery';

type ResourcesQueryProps = {
  start: string;
  end: string;
};

export const useResourcesQuery = (
  { start, end }: ResourcesQueryProps,
  config?: ConstructReactQueryConfig,
) => {
  const url = `/api/v1/projects?cabinet_shop=true&headquarters=true&timeoff=true&&start=${start}&end=${end}`;
  return constructReactQuery<Resource[]>(url, config);
};
