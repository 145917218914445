
import { addDays, endOfDay, format, startOfWeek, StartOfWeekOptions } from 'date-fns';

export const DAYS_OF_THE_WEEK = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
} as const;

/**
 * Get the start and end of a week for a given date, with the option to include weekends.
 * The output of this function is a string in ISO format, which is the format used
 * by FullCalendar. These times are in UTC format, so the end date may _look_ like it's
 * for the next week/day, because in UTC time the week/day may be different.
 *
 * @param date - The date to get the week start and end for.
 * @param includeWeekends - Whether to include weekends in the week.
 * @returns The start and end of the week for the given date as ISO strings.
 * @example
 * getWeekStartAndEnd('2024-01-01', true) // { startDate: '2024-01-01T00:00:00.000Z', endDate: '2024-01-07T23:59:59.999Z' }
 * getWeekStartAndEnd('2024-01-01', false) // { startDate: '2024-01-01T00:00:00.000Z', endDate: '2024-01-05T23:59:59.999Z' }
 */
export const getWeekStartAndEnd = (date: string, includeWeekends: boolean) => {
  const currentDate = new Date(date);

  const weekOptions: StartOfWeekOptions = {
    weekStartsOn: DAYS_OF_THE_WEEK.MONDAY,
  };

  const startDate = startOfWeek(currentDate, weekOptions);

  const additionalDaysInWeek = includeWeekends ? 6 : 4;
  const endDate = endOfDay(addDays(startDate, additionalDaysInWeek));

  return {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  };
};

/**
 * Get all days in a date range.
 *
 * @param startDate - The start date of the range.
 * @param endDate - The end date of the range.
 * @returns An array of Date objects representing each day in the range.
 */
export const getDaysInRange = (startDate: string, endDate: string) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const days: Date[] = [];

  for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
    days.push(new Date(d));
  }

  return days;
};

/**
 * format a string or date into a date string with yyyy-MM-dd format
 *
 * @param date - The date to format.
 * @returns A string representing the date in yyyy-MM-dd format.
 */
export const formatDay = (date: Date | string) => format(new Date(date), 'yyyy-MM-dd');
