import {
  constructReactQuery,
  ConstructReactQueryConfig,
} from '@/utils/constructReactQuery';
import type { ProjectCalendarEvent } from '@/types';

type EventsQueryProps = {
  start: string;
  end: string;
};

export const useEventsQuery = (
  { start, end }: EventsQueryProps,
  config?: ConstructReactQueryConfig,
) => {
  const url = `/api/v1/events?start=${start}&end=${end}`;
  return constructReactQuery<ProjectCalendarEvent[]>(url, config);
};
