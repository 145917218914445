/* eslint no-console:0 */

// Rails functionality
import Rails from '@rails/ujs';
import '@rails/actiontext';
import '@hotwired/turbo-rails';

// Make accessible for Electron and Mobile adapters
window.Rails = Rails;

require('@rails/activestorage').start();

// ActionCable Channels
import './channels';

// Stimulus controllers
import './controllers';

// Jumpstart Pro & other Functionality
import './src/**/*';
require('local-time').start();

// Flowbite
import 'flowbite/dist/flowbite.turbo.js';

import * as Components from './components/**/*.{js,ts,tsx,jsx}';

import registerPushNotificationServiceWorker from './registerPushNotificationServiceWorker';
import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient();
window.queryClient = queryClient;
export { queryClient };

void registerPushNotificationServiceWorker();

// Start Rails UJS
// Disabled since Rails UJS is being started by another file
// Rails.start();

/**
 * Register React Components
 * This function registers all React components in the application.
 * It uses the `Components` object to get the components and their modules.
 * It then registers the components with ReactRailsUJS.
 */
const registerComponents = () => {
  const componentsContext = {};

  for (const [_, components] of Object.entries(Components)) {
    components.forEach(({ module }) => {
      Object.entries(module).forEach(([name, component]) => {
        componentsContext[name] = component;
      });
    });
  }

  if (window && typeof window !== 'undefined') {
    console.info('registering query');
    window.invalidateQuery = queryClient.invalidateQueries;
  }

  const ReactRailsUJS = require('react_ujs');

  ReactRailsUJS.getConstructor = (name) => componentsContext[name];

  ReactRailsUJS.handleEvent('turbo:load', ReactRailsUJS.handleMount, false);
  ReactRailsUJS.handleEvent(
    'turbo:frame-load',
    ReactRailsUJS.handleMount,
    false,
  );

  // This is used when we render react components inside of turbo stream 
  // responses.
  // Manually construct your turbo stream responses with the following (note data-react-remount="true"):
  //    <turbo-stream action="replace" target="<%= dom_id(thing) %>" data-react-remount="true">
  //      <template>stuff</template>
  window.addEventListener(
    'turbo:before-stream-render',
     (e) => { 
      if (e.detail?.newStream?.dataset?.reactRemount) {
        setTimeout(() => {
          requestAnimationFrame(() => {
            ReactRailsUJS.handleMount()
          })
        }, 50) 
      }
    },
  );

  ReactRailsUJS.handleEvent(
    'turbo:before-render',
    ReactRailsUJS.handleUnmount,
    false,
  );
};

registerComponents();
