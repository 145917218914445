import { useQuery } from '@tanstack/react-query';

const getUrlPathSegments = (url: string) => {
  // Create a URL object using the current origin as a base for relative URLs
  const fullUrl = new URL(url, window.location.origin);

  // Get the pathname part (e.g., "/api/v1/calendar_events")
  const path = fullUrl.pathname;

  return path;
};

const getUrlParamsAsObject = (url: string) => {
  // Extract only the query string part using URLSearchParams
  const queryString = url.split('?')[1];
  const queryParams = new URLSearchParams(queryString);

  // Convert URLSearchParams to an object
  const paramsObject = Object.fromEntries(queryParams.entries());
  return paramsObject;
};

const getReactQueryKey = (url: string) => {
  const path = getUrlPathSegments(url);
  const params = getUrlParamsAsObject(url);

  return [path, params];
};

export type ConstructReactQueryConfig = {
  refetchInterval?: number;
};

export const constructReactQuery = <T>(
  url: string,
  options?: {
    refetchInterval?: number;
  },
) => {
  return useQuery<T>({
    queryKey: getReactQueryKey(url),
    queryFn: () => fetch(url).then((res) => res.json() as Promise<T>),
    ...options,
  });
};
