import { WeatherReport } from '@/types';
import {
  constructReactQuery,
  ConstructReactQueryConfig,
} from '@/utils/constructReactQuery';

type WeatherDaysQueryProps = {
  start: string;
  end: string;
};

export const useWeatherDaysQuery = (
  { start, end }: WeatherDaysQueryProps,
  config?: ConstructReactQueryConfig,
) => {
  const url = `/api/v1/weather_days?start=${start}&end=${end}`;
  return constructReactQuery<WeatherReport[]>(url, config);
};
