/**
 * Returns the CSRF token from the document head
 * fallback is to return nothing, which should
 * cause requests to fail.
 */
export const useCsrfToken = () => {
  return (
    document?.querySelector('[name="csrf-token"]')?.getAttribute('content') ??
    ''
  );
};
