import React from 'react';
import { clsxm } from '@/utils/clsxm';
import { AtLeast, Craftworker } from '@/types/index';

const baseClasses =
  'flex h-8 w-8 items-center justify-center rounded-full bg-white shrink-0 transition-all duration-300 hover:z-0';
const innerClasses =
  'flex items-center justify-center w-[85%] h-[85%] rounded-full bg-gray-300 text-white leading-0 font-base-bold';

const ImageAvatar: React.FC<{
  user: AtLeast<Craftworker, 'name'>;
  className?: string;
  tooltips: boolean;
}> = ({ user, className, tooltips = true }) => {
  return (
    <img
      src={user.avatarUrl}
      alt={'name'}
      className={clsxm(`${baseClasses} ${className}`)}
      data-controller={tooltips && 'tooltip'}
      data-tippy-content={
        user.name ?? `${user.firstName} ${user?.lastName?.[0]}.`
      }
      data-tippy-delay={'100ms'}
    />
  );
};

const InitialsAvatar: React.FC<{
  user: AtLeast<Craftworker, 'name'>;
  className?: string;
  tooltips: boolean;
}> = ({ user, className, tooltips = true }) => {
  let initials =
    (user?.firstName?.charAt(0) ?? '') + (user?.lastName?.charAt(0) ?? '');
  if (!initials || initials == '') {
    initials = user.name
      .split(' ')
      .map((n) => n[0])
      .join('');
  }
  return (
    <div
      className={clsxm(`${baseClasses} ${className}`)}
      data-controller={tooltips && 'tooltip'}
      data-tippy-content={
        user.name ?? `${user.firstName} ${user?.lastName?.[0]}.`
      }
      data-tippy-delay={'100ms'}
    >
      <div className={innerClasses}>{initials}</div>
    </div>
  );
};

export const CountAvatar: React.FC<{
  users: Craftworker[];
  className?: string;
}> = ({ users, className }) => {
  const count = users.length;
  const names = users
    .map((user) => `${user.firstName} ${user.lastName[0]}.`)
    .join(', ');
  return (
    <div
      className={clsxm(baseClasses, className)}
      data-controller="tooltip"
      data-tippy-content={names}
      data-tippy-delay={'100ms'}
    >
      <div className={innerClasses}>+{count}</div>
    </div>
  );
};

export const Avatar: React.FC<{
  user: AtLeast<Craftworker, 'name'>;
  className?: string;
  tooltips?: boolean;
}> = ({ user, className, tooltips = true }) => {
  if (user.avatarUrl?.includes('active_storage')) {
    return (
      <ImageAvatar user={user} className={className} tooltips={tooltips} />
    );
  } else {
    return (
      <InitialsAvatar user={user} className={className} tooltips={tooltips} />
    );
  }
};

export const AvatarGroup: React.FC<{
  users: Craftworker[];
  className?: string;
}> = ({ users, className }) => {
  const maxLength = 4;
  const filteredUsers: AtLeast<Craftworker, 'name'>[] = users;
  const additionalUsers = users.splice(
    maxLength - 1,
    users.length - maxLength + 1,
  );

  if (users.length > maxLength) {
    filteredUsers.length = maxLength;
    filteredUsers.push({ name: `+${users.length - maxLength}` });
  }
  return (
    <div
      className={clsxm('group isolate flex justify-center gap-1', className)}
    >
      {filteredUsers.map((user, i) => {
        return (
          <Avatar
            key={i}
            user={user}
            className={i !== 0 ? `-ml-3 group-hover:-ml-1` : ''}
          />
        );
      })}
      {additionalUsers.length > 0 && (
        <CountAvatar
          users={additionalUsers}
          className="-ml-3 transition-all duration-300 hover:z-0 group-hover:-ml-1"
        />
      )}
    </div>
  );
};
