export const formatAsCurrency = (valueInCents: number | string) => {
  const valueToParse =
    typeof valueInCents === 'string'
      ? parseInt(valueInCents, 10)
      : valueInCents;
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });
  const CENTS_IN_A_DOLLAR = 100;

  return currencyFormatter.format(valueToParse / CENTS_IN_A_DOLLAR);
};
