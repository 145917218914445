import { ConstructReactQueryConfig } from '@/utils/constructReactQuery';
import { useCalendarEventsQuery } from './useCalendarEventsQuery';
import { useCraftworkerOpeningsQuery } from './useCraftworkerOpeningsQuery';
import { useEventsQuery } from './useEventsQuery';
import { useProjectsQuery } from './useProjectsQuery';
import { useWeatherDaysQuery } from './useWeatherDaysQuery';

type ProjectsCalendarDataProps = {
  startDate: string;
  endDate: string;
};
export const useProjectsCalendarData = (
  { startDate, endDate }: ProjectsCalendarDataProps,
  config?: ConstructReactQueryConfig,
) => {
  const { data: craftworkerOpenings } = useCraftworkerOpeningsQuery(
    {
      start: startDate,
      end: endDate,
    },
    config,
  );
  const { data: weatherDays } = useWeatherDaysQuery(
    {
      start: startDate,
      end: endDate,
    },
    config,
  );
  const { data: projects } = useProjectsQuery(
    {
      start: startDate,
      end: endDate,
    },
    config,
  );
  const { data: events } = useEventsQuery(
    { start: startDate, end: endDate },
    config,
  );

  return {
    craftworkerOpenings: craftworkerOpenings ?? [],
    events: events ?? [],
    projects: projects ?? [],
    weatherDays: weatherDays ?? [],
  };
};
