import React from 'react';
import { clsxm } from '@/utils/clsxm';

type ButtonOrAnchorProps = {
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'outline'
    | 'ghost'
    | 'ghostDark'
    | 'warning';
  iconOnly?: boolean;
  children: React.ReactNode;
  href?: string;
} & (
  | React.ButtonHTMLAttributes<HTMLButtonElement>
  | React.AnchorHTMLAttributes<HTMLAnchorElement>
);

const Button: React.FC<ButtonOrAnchorProps> = ({
  variant = 'primary',
  iconOnly = false,
  children,
  className,
  href,
  ...props
}) => {
  const baseClasses =
    'flex gap-1 justify-center items-center rounded-md text-sm font-base-medium whitespace-nowrap overflow-hidden text-ellipsis disabled:cursor-not-allowed disabled:opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2';
  const sizeClasses = iconOnly ? 'h-8 w-8' : 'px-6 h-8';

  const variantClasses = {
    primary:
      'bg-plum hover:bg-plum-hover text-white focus-visible:outline-plum hover:text-gray-100 hover:bg-plum/80 active:bg-plum/70 focus-visible:outline-plum',
    secondary:
      'bg-gray-50 text-plum hover:bg-gray-100 focus-visible:outline-plum hover:text-gray-800',
    tertiary:
      'border border-gray-100 text-plum hover:bg-gray-50 hover:border-gray-50 focus-visible:outline-plum hover:text-gray-800',
    outline:
      'border border-plum text-plum hover:bg-white/80 focus-visible:outline-plum hover:text-gray-800',
    ghost:
      'bg-white text-plum hover:bg-gray-50 focus-visible:outline-plum hover:text-gray-800',
    ghostDark:
      'bg-plum text-white hover:bg-white/10 focus-visible:outline-gray-800 hover:text-white/80',
    warning:
      'bg-red text-white hover:bg-red-500 focus:ring-plum hover:text-white',
  };

  const classes = clsxm(
    baseClasses,
    sizeClasses,
    variantClasses[variant],
    className,
  );

  if (href) {
    return (
      <a
        href={href}
        className={classes}
        {...(props as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
      >
        {children}
      </a>
    );
  }

  return (
    <button
      className={classes}
      {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
    >
      {children}
    </button>
  );
};

export default Button;
