import React from 'react';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import dayGridPlugin from '@fullcalendar/daygrid';
import { QueryClientProvider } from '@tanstack/react-query';
import { ViewMountArg } from '@fullcalendar/core';

import { Event } from './Event';
import { DateHeader } from './DateHeader';

import { queryClient } from '@/application';
import { compareProjectEvents } from '@/components/calendars/utils/compareProjectEvents';
import { useProjectsCalendarData } from '@/hooks/useProjectsCalendarData';
import {
  CalendarViewState,
  getDateBoundsForViewSize,
  getViewSizeForFullCalendarView,
  useCalendarSettings,
} from '@/hooks/useCalendarSettings';
import {
  FullCalendarDateInfo,
  FullCalendarToolbarOptions,
  ProjectCalendarEvent,
} from '@/types';

const toolbarOptions: FullCalendarToolbarOptions = {
  dayGridWeek: {
    left: 'prev dayGridMonth,dayGridWeek,timelineDay next today',
    center: 'title',
    right: 'toggleWeekendsButton staffingResourcesButton',
  },
  dayGridMonth: {
    left: 'prev dayGridMonth,dayGridWeek,timelineDay next today',
    center: 'title',
    right: 'toggleWeekendsButton staffingResourcesButton',
  },
  timelineDay: {
    left: 'prev dayGridMonth,dayGridWeek,timelineDay next today',
    center: 'title',
    right: 'staffingResourcesButton',
  },
  resourceTimelineWeek: {
    left: 'prev next',
    center: 'title',
    right: 'dayGridWeek',
  },
};

const buttonsConfig = {
  today: 'Today',
  month: 'Month',
  week: 'Week',
  day: 'Day',
  resourceTimelineWeek: 'Resources',
};

const defaultViewState: CalendarViewState = {
  view: 'dayGridWeek',
  viewSize: 'week',
  showWeekends: false,
  ...getDateBoundsForViewSize('week', new Date().toISOString()),
};

const ProjectsCalendarDisplay: React.FC<{ apiKey: string }> = ({ apiKey }) => {
  const { calendarSettings, setCalendarSettings, toggleWeekends } =
    useCalendarSettings({
      calendarName: 'project_calendar',
      defaultViewState,
    });

  const handleViewDidMount = (arg: ViewMountArg) => {
    setCalendarSettings((prevSettings: CalendarViewState) => ({
      ...prevSettings,
      view: arg.view.type,
      viewSize: getViewSizeForFullCalendarView(arg.view.type),
    }));
  };

  const { craftworkerOpenings, events, projects, weatherDays } =
    useProjectsCalendarData(
      {
        startDate: calendarSettings.startDate,
        endDate: calendarSettings.endDate,
      },
      {
        refetchInterval: Infinity,
      },
    );

  const handleDatesSet = async (dateInfo: FullCalendarDateInfo) => {
    setCalendarSettings((prevSettings: CalendarViewState) => ({
      ...prevSettings,
      view: dateInfo.view.type,
      viewSize: getViewSizeForFullCalendarView(dateInfo.view.type),
      startDate: dateInfo.startStr,
      endDate: dateInfo.endStr,
    }));
  };

  const customButtonsConfig = {
    toggleWeekendsButton: {
      text: calendarSettings.showWeekends ? 'Hide Weekends' : 'Show Weekends',
      click: toggleWeekends,
    },
    staffingResourcesButton: {
      text: 'Staffing',
      click: () => (window.location.href = '/project_calendars/staffing_beta'),
    },
  };

  return (
    <FullCalendar
      buttonText={buttonsConfig}
      customButtons={customButtonsConfig}
      datesSet={(dateInfo) => handleDatesSet(dateInfo)}
      editable={false}
      eventClassNames={'md:px-1 cursor-pointer hover:opacity-100'}
      eventContent={(eventInfo) => {
        return (
          /** do some typescript backflips because fullcalendar types are a human nightmare */
          <Event event={eventInfo.event as unknown as ProjectCalendarEvent} />
        );
      }}
      eventDisplay="block"
      eventOrder={(a, b) => {
        /** HACK: fullcalendar types are a mess */
        const eventA = a as unknown as ProjectCalendarEvent;
        const eventB = b as unknown as ProjectCalendarEvent;
        return compareProjectEvents(
          {
            start: eventA.start,
            end: eventA.end,
            title: eventA.title,
            projectType: eventA.extendedProps.projectType,
          },
          {
            start: eventB.start,
            end: eventB.end,
            title: eventB.title,
            projectType: eventB.extendedProps.projectType,
          },
        );
      }}
      eventTextColor={'#5A4C62'}
      events={events}
      headerToolbar={toolbarOptions[calendarSettings.view]}
      height={'100%'}
      weekends={calendarSettings.showWeekends}
      initialView={calendarSettings.view}
      initialDate={calendarSettings.startDate}
      plugins={[dayGridPlugin, resourceTimelinePlugin, interactionPlugin]}
      refetchResourcesOnNavigate={true}
      resourceGroupField={'projectType'}
      resources={projects}
      slotDuration={'06:00:00'}
      slotMaxTime={'18:00:00'}
      slotMinTime={'08:00:00'}
      schedulerLicenseKey={apiKey}
      viewClassNames={'mx-2 mb-2 border rounded-t-md rounded-b overflow-hidden'}
      viewDidMount={handleViewDidMount}
      views={{
        dayGridMonth: {
          dayHeaderFormat: { weekday: 'short' },
          titleFormat: { month: 'long' },
        },
        dayGridWeek: {
          dayHeaderFormat: {
            weekday: 'short',
            day: 'numeric',
            omitCommas: true,
          },
          dayHeaderContent: (props) => (
            <DateHeader
              weatherDays={weatherDays}
              staffingResources={craftworkerOpenings}
              {...props}
            />
          ),
          titleFormat: { month: 'long', day: 'numeric' },
        },
        timelineDay: {
          dayHeaders: false,
          titleFormat: { weekday: 'long', month: 'long', day: 'numeric' },
          slotDuration: '02:00:00',
          slotMaxTime: '17:00:00',
          slotMinTime: '08:00:00',
        },
        resourceTimelineWeek: {
          resourceAreaHeaderContent: 'Project',
          resourceAreaWidth: '20%',
          slotDuration: '02:00:00',
          slotMaxTime: '17:00:00',
          slotMinTime: '08:00:00',
          titleFormat: { month: 'long', day: 'numeric' },
        },
      }}
    />
  );
};

export const ProjectsCalendar: React.FC<{ apiKey: string }> = ({ apiKey }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ProjectsCalendarDisplay apiKey={apiKey} />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
};
