import {
  constructReactQuery,
  ConstructReactQueryConfig,
} from '@/utils/constructReactQuery';
import type { Project } from '@/types/index';

type ProjectsQueryProps = {
  start: string;
  end: string;
};

export const useProjectsQuery = (
  { start, end }: ProjectsQueryProps,
  config?: ConstructReactQueryConfig,
) => {
  const url = `/api/v1/projects?start=${start}&end=${end}`;
  return constructReactQuery<Project[]>(url, config);
};
