// See the Tailwind default theme values here:
// https://github.com/tailwindcss/tailwindcss/blob/master/stubs/defaultConfig.stub.js
const colors = require('tailwindcss/colors');
const defaultTheme = require('tailwindcss/defaultTheme');
const flowbite = require('flowbite-react/tailwind');

const { fontFamily } = defaultTheme;

const grosaRegularFont = '"Grosa-Regular", sans-serif';
const grosaMediumFont = '"Grosa-Medium", sans-serif';
const grosaBold = '"Grosa-Bold", sans-serif';
const grosaMonoRegular = '"GrosaMonoTrial-Regular", sans-serif';
const grosaMonoMedium = '"GrosaMonoTrial-Medium", sans-serif';
const grosaMonoBold = '"GrosaMonoTrial-Bold", sans-serif';

const PLUM_CODE = '#32213B';
const DENIM_CODE = '#6EA7B8';

/** @type {import('tailwindcss').Config} **/
module.exports = {
  plugins: [
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/forms'),
    require('@tailwindcss/typography'),
    require('flowbite/plugin'),
    require('@tailwindcss/container-queries'),
    function ({ addVariant }) {
      addVariant('child', '& > *');
      addVariant('not-first', '& > *:not(:first-child)');
      addVariant('child-hover', '& > *:hover');
    },
    flowbite.plugin(),
  ],

  content: [
    './app/helpers/**/*.rb',
    './app/components/**/*.rb',
    './app/components/**/*.erb',
    './app/javascript/**/*.js',
    './app/javascript/**/*.tsx',
    './app/javascript/**/*.jsx',
    './app/views/**/*.erb',
    './app/views/**/*.haml',
    './app/views/**/*.slim',
    './lib/jumpstart/app/views/**/*.erb',
    './lib/jumpstart/app/helpers/**/*.rb',
    './node_modules/flowbite/**/*.js',
    './emails/*.tsx',
    './react-email/**/*.tsx',
    flowbite.content(),
  ],
  safelist: ['bg-caution', 'bg-notification', 'h-[16px]'],

  theme: {
    container: {
      center: true,
      padding: {
        DEFAULT: '16px',
      },
    },
    screens: {
      sm: '540px',
      md: '768px',
      lg: '991px',
      xl: '1246px',
      '2xl': '1536px',
    },
    extend: {
      colors: {
        primary: PLUM_CODE, // Deprecated
        secondary: DENIM_CODE, // Deprecated
        tertiary: colors.gray, // Deprecated
        danger: colors.red, // Deprecated
        'code-400': '#fefcf9', // Deprecated
        'code-600': '#3c455b', // Deprecated

        // SHORTCUT COLOR NAMES
        plum: {
          DEFAULT: PLUM_CODE,
          active: '#513d5c',
          hover: '#453050',
        },
        denim: {
          DEFAULT: DENIM_CODE,
        },

        // UTILITY COLORS
        black: {
          DEFAULT: '#000000',
        },
        white: {
          DEFAULT: '#FFFFFF',
        },
        link: {
          DEFAULT: '#58117D',
        },

        // SYSTEM COLORS
        warning: {
          DEFAULT: '#FF4343',
        },
        success: {
          DEFAULT: '#7EDD3F',
        },
        caution: {
          DEFAULT: '#F8B13E',
        },
        notification: {
          DEFAULT: '#1F8AFF',
        },

        // PROJECT TYPE COLORS
        interior: {
          DEFAULT: '#6A397E',
        },
        exterior: {
          DEFAULT: '#2874A6',
        },
        cabinets: {
          DEFAULT: '#5D6D7E',
        },
        specialty: {
          DEFAULT: '#39767E',
        },

        // COLOR FAMILIES
        gray: {
          DEFAULT: '#2B1F33',
          900: '#2B1F33',
          800: '#3A303D',
          700: '#4A4047',
          600: '#595052',
          500: '#808080',
          400: '#999999',
          300: '#B3B3B3',
          200: '#CCCCCC',
          100: '#E6E6E6',
          50: '#F2F2F2',
          25: '#FBFBFB',
        },
        blue: {
          DEFAULT: DENIM_CODE,
          900: '#37535C',
          800: '#486D78',
          700: '#527D8A',
          600: DENIM_CODE,
          500: '#92BDCA',
          400: '#D3E4EA',
          300: '#D3E4EA',
          200: '#E2EDF1',
          100: '#F0F6F8',
          50: '#FAFEFF',
        },
        yellow: {
          DEFAULT: '#C59B28', // 600
          800: '#6D5616',
          700: '#8F711D',
          600: '#C59B28',
          500: '#DBAD2C',
          400: '#E8CA76',
          300: '#F6EACA',
          200: '#FAF6EB',
          100: '#FDFBF4',
          50: '#FDFCF9',
        },
        red: {
          DEFAULT: '#FF6C63', // 600
          900: '#7F3631',
          800: '#A64640',
          700: '#E46159',
          600: '#FF6C63',
          500: '#FF9F99',
          400: '#FFB6B1',
          300: '#FFC7C4',
          200: '#FFDAD8',
          100: '#FFE5E2',
          50: '#FFF0EC',
        },
        green: {
          DEFAULT: '#8CB86E',
          900: '#465C37',
          800: '#5B7848',
          700: '#7EA563',
          600: '#8CB86E',
          500: '#B5D1A2',
          400: '#C6DCB7',
          300: '#DAE8D1',
          200: '#E7F2E0',
          100: '#EDF6E9',
          50: '#F5FBF2',
        },
        purple: {
          DEFAULT: '#B975E0', // 600
          900: '#5C3A70',
          800: '#784C92',
          700: '#A669C9',
          600: '#B975E0',
          500: '#D1A5EB',
          400: '#DCBAF0',
          300: '#E4CBF2',
          200: '#EDDCF7',
          100: '#F4E9FA',
          50: '#F8F2FD',
        },
      },
      containers: {
        '4xs': '8rem',
        '3xs': '12rem',
        '2xs': '16rem',
      },
      backgroundImage: {},
      borderRadius: {
        DEFAULT: '4px',
        sm: '2px',
        md: '6px',
        lg: '8px',
        xl: '12px',
      },
      borderColor: {
        DEFAULT: '#E6E6E6',
      },
      boxShadow: {
        DEFAULT: '0px 5px 13px -4px rgba(0, 0, 0, 0.12)',
        XS: '0px 1px 2px rgba(0, 0, 0, 0.25)',
        sm: '0px 2px 6px -1px rgba(0, 0, 0, 0.12)',
        lg: '0px 6px 20px -5px rgba(0, 0, 0, 0.12)',
        inner: 'inset 0px 2px 4px 0px rgba(0, 0, 0, 0.05)',
        'inner-dark': 'inset 0px 2px 4px 0px rgba(0, 0, 0, 0.1)',
        'inner-darker': 'inset 0px 2px 4px 0px rgba(0, 0, 0, 0.2)',
        dark: '0px 5px 13px -4px rgba(0, 0, 0, 0.35)',
        'sm-dark': '0px 2px 8px -1px rgba(0, 0, 0, 0.25)',
        'lg-dark': '0px 6px 20px -5px rgba(0, 0, 0, 0.20)',
      },
      dropShadow: {
        'sm-dark': '0px 1px 0.5px rgba(0, 0, 0, 0.25)',
        'md-dark': '0px 2px 1px rgba(0, 0, 0, 0.20)',
      },
      fontFamily: {
        'base-regular': [grosaRegularFont],
        'base-medium': [grosaMediumFont],
        'base-bold': [grosaBold],
        'mono-regular': [grosaMonoRegular],
        'mono-medium': [grosaMonoMedium],
        'mono-bold': [grosaMonoBold],
        sans: ['Grosa', ...fontFamily.sans],
      },
      fontSize: {
        xl: ['1.25rem', { lineHeight: '120%' }],
        lg: ['1.125rem', { lineHeight: '120%' }],
        md: ['1rem', { lineHeight: '120%' }],
        sm: ['0.875rem', { lineHeight: '120%' }],
        xs: ['0.75rem', { lineHeight: '120%' }],
        '2xs': ['0.625rem', { lineHeight: '120%' }],
        caption: ['.75rem', { lineHeight: '90%' }],
        'caption-sm': ['0.625rem', { lineHeight: '90%' }],
      },
      minWidth: {
        kanban: '20rem',
      },
      typography: (theme) => ({
        DEFAULT: {
          css: {
            color: theme('colors.gray'),
          },
        },
      }),
    },
  },

  // Opt-in to TailwindCSS future changes
  future: {},
};
