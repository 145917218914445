import { CalendarEvent } from '@/types';
import {
  constructReactQuery,
  ConstructReactQueryConfig,
} from '@/utils/constructReactQuery';

type CalendarEventsQueryParams = {
  start: string;
  end: string;
  options?: Partial<{
    cabinet_shop_openings?: boolean;
    collated?: boolean;
    craftworker_openings?: boolean;
    headquarters_openings?: boolean;
    project_openings?: boolean;
    shifts?: boolean;
    timeoff_openings?: boolean;
  }>;
};

export const useCalendarEventsQuery = <T = CalendarEvent>(
  { start, end, options = {} }: CalendarEventsQueryParams,
  config?: ConstructReactQueryConfig,
) => {
  const params = new URLSearchParams(
    // remove undefined values so URLSearchParams doesn't throw an error
    JSON.parse(
      JSON.stringify({
        start,
        end,
        ...options,
      }),
    ),
  );

  const url = `/api/v1/calendar_events?${params.toString()}`;
  return constructReactQuery<T[]>(url, config);
};
