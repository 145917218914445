import React from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';

export const MoreLink: React.FC<{ num: number }> = ({ num }) => {
  return (
    <div className="flex h-8 items-center justify-center rounded px-4 py-2 font-base-medium leading-none opacity-60 transition-colors hover:bg-white hover:opacity-100">
      <PlusIcon className="inline-block h-3 w-3" /> {num} More
    </div>
  );
};
