import React, { Fragment, useEffect } from 'react';
import { isWithinInterval } from 'date-fns';
import { ToggleSwitch } from 'flowbite-react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { ResourceLabel } from './ResourceLabel';
import CollatedEvent from './CollatedEvent';
import { CraftworkerAvailability } from './CraftworkerAvailability';

import { queryClient } from '@/application';
import {
  CalendarViewState,
  useCalendarSettings,
} from '@/hooks/useCalendarSettings';
import { useStaffingCalendarData } from '@/hooks/useStaffingCalendarData';
import { Resource } from '@/types';
import Button from '@/ui/Button';
import { clsxm } from '@/utils/clsxm';
import { formatDay, getDaysInRange, getWeekStartAndEnd } from '@/utils/dates';
import { formatDate, formatDateRange } from '@/utils/dateFormatter';

type CalendarNavProps = {
  calendarSettings: CalendarViewState;
  setCalendarSettings: React.Dispatch<React.SetStateAction<CalendarViewState>>;
};

const CalendarNav = ({
  calendarSettings,
  setCalendarSettings,
}: CalendarNavProps) => {
  const { startDate, endDate, showWeekends } = calendarSettings;
  const formattedDateRange = formatDateRange(startDate, endDate, {
    month: 'short',
    day: 'numeric',
  });

  const isTodayInView = isWithinInterval(new Date(), {
    start: startDate,
    end: endDate,
  });

  const handleNextDateRange = () => {
    const nextWeekStart = new Date(startDate);
    nextWeekStart.setDate(nextWeekStart.getDate() + 7);
    const { startDate: newStartDate, endDate: newEndDate } = getWeekStartAndEnd(
      nextWeekStart.toISOString(),
      showWeekends,
    );
    setCalendarSettings((prev) => ({
      ...prev,
      startDate: newStartDate,
      endDate: newEndDate,
    }));
  };

  const handlePrevDateRange = () => {
    const prevWeekStart = new Date(startDate);
    prevWeekStart.setDate(prevWeekStart.getDate() - 7);
    const { startDate: newStartDate, endDate: newEndDate } = getWeekStartAndEnd(
      prevWeekStart.toISOString(),
      showWeekends,
    );
    setCalendarSettings((prev) => ({
      ...prev,
      startDate: newStartDate,
      endDate: newEndDate,
    }));
  };

  const toggleWeekends = () => {
    setCalendarSettings((prev) => {
      const newShowWeekends = !prev.showWeekends;
      const { startDate: newStartDate, endDate: newEndDate } =
        getWeekStartAndEnd(prev.startDate, newShowWeekends);
      return {
        ...prev,
        showWeekends: newShowWeekends,
        startDate: newStartDate,
        endDate: newEndDate,
      };
    });
  };

  const handleNavigateToToday = () => {
    const { startDate: newStartDate, endDate: newEndDate } = getWeekStartAndEnd(
      new Date().toISOString(),
      showWeekends,
    );
    setCalendarSettings((prev) => ({
      ...prev,
      startDate: newStartDate,
      endDate: newEndDate,
    }));
  };

  return (
    <header className="flex items-center justify-between p-2">
      {/* Left Side */}
      <div className="flex items-center gap-2">
        <Button
          variant={isTodayInView ? 'primary' : 'secondary'}
          onClick={handleNavigateToToday}
        >
          Today
        </Button>
        <ToggleSwitch
          label="Show Weekends"
          checked={showWeekends}
          onChange={toggleWeekends}
        />
      </div>

      {/* Middle */}
      <div className="flex items-center gap-2">
        <Button variant="secondary" iconOnly onClick={handlePrevDateRange}>
          <ChevronLeftIcon className="h-5 w-5" />
        </Button>
        <h2>{formattedDateRange}</h2>
        <Button variant="secondary" iconOnly onClick={handleNextDateRange}>
          <ChevronRightIcon className="h-5 w-5" />
        </Button>
      </div>

      {/* Right Side */}
      <div className="flex items-center gap-2">
        <Button
          variant="secondary"
          href={`/shifts/in_range?modal=true&starts_at=${startDate}&ends_at=${endDate}`}
          data-turbo-frame="modal"
        >
          Publish Shifts
        </Button>
        <Button variant="secondary" href="/project_calendars">
          Projects
        </Button>
      </div>
    </header>
  );
};

type CalendarGridContainerProps = {
  children: React.ReactNode;
  days: Date[];
  classNames?: string;
};

const CalendarGridContainer = ({
  children,
  days,
  classNames,
}: CalendarGridContainerProps) => (
  <div
    className={classNames}
    style={{
      display: 'grid',
      gridTemplateColumns: `minmax(100px, 1fr) repeat(${days.length}, minmax(120px, 1fr))`,
    }}
  >
    {children}
  </div>
);

type DateCellProps = {
  date: Date;
};

const DateCell = ({ date }: DateCellProps) => {
  const dateStr = date.toISOString();

  const DayOfWeek = formatDate(dateStr, { weekday: 'short' });
  const DayOfMonth = formatDate(dateStr, { day: 'numeric' });

  return (
    <div className="flex justify-between p-2">
      <div>{DayOfWeek}</div>
      <div>{DayOfMonth}</div>
    </div>
  );
};

const defaultViewState: CalendarViewState = {
  view: 'resourceTimelineWeek',
  viewSize: 'week',
  showWeekends: false,
  ...getWeekStartAndEnd(new Date().toISOString(), false),
};

const Calendar = () => {
  const { calendarSettings, setCalendarSettings } = useCalendarSettings({
    calendarName: 'staffing-calendar-dos',
    defaultViewState,
  });

  const { startDate, endDate } = calendarSettings;

  const {
    craftworkerOpenings,
    availablePaintersByDay,
    calendarEvents: events,
    resources,
  } = useStaffingCalendarData({
    startDate,
    endDate,
  });

  const days = getDaysInRange(
    calendarSettings.startDate,
    calendarSettings.endDate,
  );

  const resourcesUniqByLocation = resources?.reduce(
    (acc: Record<string, Resource>, resource) => {
      if (!acc[resource.locationId]) {
        acc[resource.locationId] = resource;
      }
      return acc;
    },
    {},
  );

  const eventsByLocationAndDay = events?.reduce(
    (acc: Record<string, Record<string, CollatedEvent>>, event) => {
      if (!acc[event.locationId]) {
        acc[event.locationId] = {};
      }
      const eventStartDay = formatDay(event.start);
      Object.assign(acc, {
        [event.locationId]: {
          ...acc[event.locationId],
          [eventStartDay]: {
            ...event,
            extendedProps: {
              accountOwnerName: event.accountOwnerName,
              eventId: event.eventId,
              locationId: event.locationId,
              locationShortAddress: event.locationShortAddress,
              projectId: event.projectId,
              reworkReasonId: event.reworkReasonId,
              reworkReasonTitle: event.reworkReasonTitle,
              shifts: event.shifts,
              staffing: event.staffing,
            },
          },
        },
      });
      return acc;
    },
    {},
  );

  console.log(eventsByLocationAndDay);

  useEffect(() => {
    localStorage.setItem('staffing_calendar', JSON.stringify(calendarSettings));
  }, [calendarSettings]);

  return (
    <div className="grid max-h-full grid-rows-[auto_1fr]">
      <CalendarNav
        calendarSettings={calendarSettings}
        setCalendarSettings={setCalendarSettings}
      />
      <div
        className="mx-2 mb-2 grid grid-rows-[auto_1fr] overflow-hidden rounded-md border border-gray-100" // +1 for the resource column
      >
        {/* Header Row */}
        <CalendarGridContainer days={days}>
          <div className="border-b border-r p-2 font-bold">Resources</div>
          {days.map((date, i) => (
            <div
              className={clsxm(
                'divide-y border-b border-r font-bold',
                i === days.length - 1 && 'border-r-0',
              )}
              key={i}
            >
              <DateCell date={date} />
              <CraftworkerAvailability
                date={date}
                craftworkerOpenings={craftworkerOpenings}
                dailyPainterAvailability={availablePaintersByDay}
              />
            </div>
          ))}
        </CalendarGridContainer>

        {/* Resource Rows */}
        <CalendarGridContainer
          days={days}
          classNames="no-scrollbar max-h-full overflow-y-auto"
        >
          {resourcesUniqByLocation &&
            Object.values(resourcesUniqByLocation).map((resource) => (
              <Fragment key={resource.locationId}>
                <ResourceLabel resource={resource} />
                {days.map((day, index) => {
                  const event =
                    eventsByLocationAndDay?.[resource.locationId]?.[
                      formatDay(day)
                    ];
                  return (
                    <div
                      key={index}
                      className={clsxm(
                        'border-b border-r bg-gray-50',
                        index === days.length - 1 && 'border-r-0',
                        formatDay(day) === formatDay(new Date()) &&
                          'bg-gray-100',
                      )}
                    >
                      {event && (
                        <CollatedEvent
                          eventInfo={{
                            event,
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </Fragment>
            ))}
        </CalendarGridContainer>
      </div>
    </div>
  );
};

export const StaffingCalendarDos: React.FC<{ apiKey: string }> = ({
  apiKey,
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Calendar />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

// no-op comment
