import React from 'react';
import Tippy from '@tippyjs/react';

import ProjectTypeBadge from '@/components/ProjectTypeBadge';
import { CountAvatar, Avatar } from '@/ui/Avatars';
import { BandaidIcon } from '@/ui/Icons';
import { ProjectCalendarEvent, ProjectType } from '@/types';
import { formatAsCurrency } from '@/utils/formatAsCurrency';

type TooltipContentProps = {
  event: ProjectCalendarEvent;
};
// note: This must be a forwardref to pass to Tippy. For more details,
//       see: https://github.com/atomiks/tippyjs-react?tab=readme-ov-file#component-children
const TooltipContent = React.forwardRef<HTMLDivElement, TooltipContentProps>(
  ({ event }, ref) => {
    const {
      balance,
      painters,
      customerName,
      potential,
      internalId,
      projectType,
      customTitle,
      salesStatus,
    } = event.extendedProps;

    return (
      <div
        ref={ref}
        className="max-h-[calc(100svh_-_64px)] space-y-2 overflow-y-auto rounded-md bg-plum p-4 text-sm text-white shadow"
      >
        <div className="flex items-center gap-2">
          <ProjectTypeBadge projectType={projectType} />
          <div>
            <div className="truncate font-base-medium text-xs text-white">
              {customTitle ? (
                <span>{customTitle}</span>
              ) : (
                <span>{internalId}</span>
              )}
            </div>
            <h4>{customerName}</h4>
          </div>
        </div>
        <hr className="opacity-20" />
        <ul>
          <li className="flex gap-2 font-base-medium capitalize">
            <strong>Status:</strong> {salesStatus}
          </li>
          <li className="flex gap-2 font-base-medium capitalize tabular-nums">
            <strong>Value:</strong> {formatAsCurrency(potential)}
          </li>
          <li className="flex gap-2 font-base-medium capitalize tabular-nums">
            <strong>Balance:</strong> {formatAsCurrency(balance)}
          </li>
        </ul>
        <hr className="opacity-20" />
        <h4>Crew</h4>
        <ul className="space-y-2">
          {painters && painters.length > 0 ? (
            painters.map((painter) => (
              <li
                className="flex items-center gap-2"
                key={`tooltip-${event.id}-${painter.id ?? painter.prefixId}`}
              >
                <Avatar user={painter} tooltips={false} />
                <span className="font-base-medium">
                  {painter.firstName} {painter.lastName}
                </span>
              </li>
            ))
          ) : (
            <li>No painters assigned</li>
          )}
        </ul>
      </div>
    );
  },
);

const BadgeWithRef = React.forwardRef<
  HTMLDivElement,
  { projectType: ProjectType }
>(({ projectType }, ref) => {
  return (
    <div ref={ref}>
      <ProjectTypeBadge projectType={projectType} />
    </div>
  );
});

export const Event = ({ event }: { event: ProjectCalendarEvent }) => {
  const {
    painters,
    customerName,
    internalId,
    projectType,
    customTitle,
    salesStatus,
    reworkReason,
  } = event.extendedProps;

  return (
    <div className="flex h-10 w-full items-center gap-2 rounded-md bg-white px-1 text-sm leading-none shadow outline-gray-200 transition-all duration-200 @container/event hover:bg-gray-25 hover:outline md:h-12 md:px-2">
      <Tippy content={<TooltipContent event={event} />} interactive>
        <BadgeWithRef projectType={projectType} />
      </Tippy>

      <div className="flex-1 overflow-hidden">
        <div className="truncate font-base-medium text-xs text-plum/60">
          {customTitle ? <span>{customTitle}</span> : <span>{internalId}</span>}
        </div>
        <div className="truncate font-base-bold">{customerName}</div>
      </div>
      {reworkReason && (
        <span
          className={
            'flex h-8 w-8 items-center justify-center rounded-full bg-yellow-200 text-yellow-600'
          }
          data-controller="tooltip"
          data-tippy-content={reworkReason}
        >
          <BandaidIcon />
        </span>
      )}
      {painters && painters.length > 0 && (
        <CountAvatar users={painters} className="hidden @3xs/event:flex" />
      )}
      {salesStatus !== 'won' && (
        <span className="badge bg-gray-400">{salesStatus}</span>
      )}
    </div>
  );
};
