import React from 'react';

import { Listbox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { clsxm } from '@/utils/clsxm';

type SelectProps = {
  defaultValue?: Option;
  onChange: (value: Option) => void;
  children: React.ReactNode;
  value: Option | undefined;
  title: string;
  freeSpaceRight: number;
  freeSpaceBelow: number;
};

const Select: React.FC<SelectProps> = ({
  defaultValue,
  onChange,
  children,
  value,
  title,
  freeSpaceRight,
  freeSpaceBelow,
}) => {
  const menuRef = React.useRef<HTMLDivElement>(null);
  const [menuLeft, setMenuLeft] = React.useState(0);
  const [maxMenuHeight, setMaxMenuHeight] = React.useState<number | null>(null);

  React.useEffect(() => {
    if (!menuRef.current) return;
    const menuRect = menuRef.current.getBoundingClientRect();
    const menuHeight = menuRect.height;
    const menuWidth = menuRect.width;

    // set the max menu height based on available space below pill
    if (menuHeight > freeSpaceBelow) {
      setMaxMenuHeight(freeSpaceBelow - 16);
    } else {
      setMaxMenuHeight(null);
    }
    //  set left offset of menu relative to pill based on available space to the right of pill
    if (menuWidth > freeSpaceRight) {
      const leftOffset = menuHeight > freeSpaceBelow ? 48 : 16;
      setMenuLeft((menuWidth - freeSpaceRight + leftOffset) * -1);
    } else {
      setMenuLeft(-4);
    }
  }, [menuRef.current]);

  return (
    <Listbox defaultValue={defaultValue} onChange={onChange} value={value}>
      {({ open, ...rest }) => (
        <div className="relative">
          <Listbox.Button className="leading-0 flex h-full w-full items-center gap-1 whitespace-nowrap rounded-full pl-1 pr-0.5 font-base-medium text-xs text-gray-600 focus:outline-none focus:ring-0 group-hover:text-plum">
            {(selectedItem) => {
              const selectedOption = selectedItem?.value;
              return <>{selectedOption?.label || 'Select an option'}</>;
            }}
          </Listbox.Button>
          <Listbox.Options
            static
            ref={menuRef}
            style={{ left: menuLeft, maxHeight: maxMenuHeight || 'none' }}
            className={clsxm(
              !open ? 'invisible' : 'visible',
              'absolute top-[calc(100%_+_12px)] z-10 w-max overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
            )}
          >
            <span className="mx-2 mb-1 block border-b px-2 py-1 font-base-medium text-gray-400">
              {title}
            </span>
            {children}
          </Listbox.Options>
        </div>
      )}
    </Listbox>
  );
};

export type Option = {
  label: string;
  name: string;
  value: string | number;
};

type SelectOptionProps = {
  option: Option;
  children?: React.ReactNode;
};

export const SelectOption: React.FC<SelectOptionProps> = ({
  option,
  children,
}) => {
  return (
    <Listbox.Option
      className={({ active }) =>
        clsxm(
          active ? 'bg-gray-25' : '',
          'relative mx-1 min-w-[150px] cursor-default select-none rounded px-3 py-2',
        )
      }
      key={option.value}
      value={option}
    >
      {({ selected, active }) => (
        <div className="flex items-center justify-center gap-1">
          <span
            className={clsxm(
              selected ? 'font-base-medium' : 'font-base-regular',
              'block w-full truncate',
            )}
          >
            {option.label}
          </span>

          {selected && (
            <CheckIcon className="h-4 w-4 shrink-0" aria-hidden="true" />
          )}
        </div>
      )}
    </Listbox.Option>
  );
};

export default Select;
