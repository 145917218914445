import React from 'react';

import { CraftworkerOpening, DailyPainterAvailability } from '@/types';
import { AvatarGroup } from '@/ui/Avatars';
import { formatDay } from '@/utils/dates';

type CraftworkerAvailabilityProps = {
  craftworkerOpenings: CraftworkerOpening[];
  date: Date;
  dailyPainterAvailability: DailyPainterAvailability[];
};

export const CraftworkerAvailability: React.FC<
  CraftworkerAvailabilityProps
> = ({ craftworkerOpenings, date, dailyPainterAvailability }) => {
  const key = formatDay(date);
  const dailyCraftworkerOpenings = craftworkerOpenings?.filter(({ start }) => {
    return formatDay(start) === key;
  });

  const availableCraftworkers = dailyCraftworkerOpenings?.map(
    (cwo) => cwo.extendedProps.craftworker,
  );
  const openCraftworkerOpeningsModal = () => {
    Turbo.visit(
      `/calendar_events?craftworker_openings=true&modal=true&start=${date}&end=${date}`,
      {
        frame: 'modal',
      },
    );
  };

  const datePainterCount = dailyPainterAvailability?.find(
    ({ date }) => formatDay(date) === key,
  );

  const staffedPercentage = Math.round(
    (1 -
      (dailyCraftworkerOpenings.length ?? 0) / (datePainterCount?.count ?? 1)) *
      100,
  );

  let progressColor;

  if (staffedPercentage > 0 && staffedPercentage < 33) {
    progressColor = 'bg-warning';
  } else if (staffedPercentage >= 33 && staffedPercentage < 66) {
    progressColor = 'bg-orange-500';
  } else if (staffedPercentage >= 66 && staffedPercentage < 100) {
    progressColor = 'bg-caution';
  } else if (staffedPercentage >= 100) {
    progressColor = 'bg-success';
  } else {
    progressColor = 'bg-transparent';
  }

  return (
    <div className="flex w-full flex-col items-center justify-center gap-1 text-sm font-normal">
      {dailyCraftworkerOpenings.length > 0 ? (
        <button
          onClick={openCraftworkerOpeningsModal}
          className="flex h-10 w-full cursor-pointer items-center justify-center"
        >
          <AvatarGroup users={availableCraftworkers} />
        </button>
      ) : (
        <div className="flex h-10 w-full items-center justify-center text-sm leading-none text-gray-500">
          Fully Staffed
        </div>
      )}
      <div className="h-1 w-full bg-gray-200">
        <span
          style={{ width: `${staffedPercentage}%` }}
          className={`${progressColor} block h-full rounded`}
        ></span>
      </div>
    </div>
  );
};

export default CraftworkerAvailability;
