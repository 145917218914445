'use client';
import React from 'react';
import { Avatar } from '../ui/Avatars';
import { ChevronDownIcon, ExclamationTriangleIcon } from '@heroicons/react/16/solid';
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/react';
import clsx from 'clsx';

type User = {
    id: number;
    name: string;
    avatarUrl: string;
    firstName: string;
    lastName: string;
};

export type Craftworker = {
    id: number;
    name: string;
    user: User;
    avatarUrl: string;
    totalHours: number;
    totalTimesheets: number;
    needsApproval: boolean;
    selected: boolean;
};

type CraftworkerListProps = {
    craftworkers: Craftworker[];
};

export function CraftworkerList({ craftworkers }: CraftworkerListProps) {
    const allSelected = craftworkers.every(craftworker => !craftworker.selected);
    const options = craftworkers.map(craftworker => ({
        label: craftworker.name,
        value: craftworker.id,
        name: craftworker.name,
        selected: craftworker.selected,
    }));
    const selected = options.find(opt => opt.selected);
    const handleSelect = (id?: number | string) => {
      const params = new URLSearchParams(window.location.search);
      if (id) {
        params.set('craftworker', id.toString());
      } else {
        params.delete('craftworker');
      }
      window.location.href = `${window.location.pathname}?${params}`;
    }
    return (
        <>
          <div className="md:hidden flex flex-col overflow-y-auto border-b border-gray-100">
            <Listbox value={selected} onChange={(opt) => handleSelect(opt.value)}>
              <ListboxButton
                className={clsx(
                  'relative w-full h-9 py-1.5 px-3 text-left text-sm flex justify-between items-center',
                  'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2'
                )}
              >
                {selected?.name ?? 'All'}
                <ChevronDownIcon
                  className="group pointer-events-none size-4 fill-plum"
                  aria-hidden="true"
                />
              </ListboxButton>
              <ListboxOptions
                anchor="bottom"
                transition
                className={clsx(
                  'w-[var(--button-width)] border p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none bg-white',
                  'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
                )}
              >
                <ListboxOption
                    key="all"
                    value={{ label: "All", value: "" }}
                    className={clsx(
                      "group flex cursor-default items-center gap-2 py-1.5 px-3 rounded-md select-none data-[focus]:bg-gray-100",
                      !selected && "bg-plum text-white"
                    )}
                  >
                    <Avatar user={{ firstName: 'All', lastName: '' }} />
                    <div className="text-sm">All</div>
                  </ListboxOption>
                {craftworkers.map((craftworker) => (
                  <ListboxOption
                    key={craftworker.name}
                    value={{ label: craftworker.name, value: craftworker.id }}
                    className={clsx(
                      "group flex cursor-default items-center gap-2 py-1.5 px-3 rounded-md select-none data-[focus]:bg-gray-100",
                      craftworker.selected && "bg-plum text-white"
                    )}
                  >
                    <Avatar user={{ ...craftworker.user, avatarUrl: craftworker.avatarUrl }} />
                    <div className="text-sm">{craftworker.name}</div>
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Listbox>
          </div>
          <div className="hidden md:flex flex-col overflow-y-auto">
            <ul className="overflow-auto w-full">
              <li key="all" className="border-b">
                <button
                  onClick={() => handleSelect()}
                  className={`flex gap-2 p-2 items-center hover:bg-gray-100 w-full ${allSelected && 'bg-plum-active text-white group'}`}
                >
                  <Avatar user={{ firstName: 'All', lastName: '' }} />
                  <div className="w-full">
                    <span className="flex items-center justify-between gap-2 truncate font-base-bold leading-none">
                      All
                    </span>
                  </div>
                </button>
              </li>
              {craftworkers.map(craftworker => (
                  <li key={craftworker.id} className="border-b">
                      <button
                        onClick={() => {
                          handleSelect(craftworker.id);
                        }}
                        className={`flex gap-2 p-2 hover:bg-gray-100 w-full ${craftworker.selected && 'bg-plum-active text-white group'}`}
                      >
                        <Avatar user={{ ...craftworker.user, avatarUrl: craftworker.avatarUrl }} />
                        <div className="w-full">
                          <span className={`flex items-center justify-between gap-2 truncate font-base-bold leading-none ${craftworker.selected && 'group-hover:text-black'}`}>
                            {craftworker.name} {craftworker.needsApproval ? <ExclamationTriangleIcon data-controller="tooltip" data-tippy-content="Needs Approval" className="h-4 w-4 text-yellow-500" /> : ""}
                          </span>
                          <span className={`text-gray-500 text-sm tabular-nums flex ${craftworker.selected && ' text-white group-hover:text-black'}`}>
                            {`${craftworker.totalTimesheets} timesheet${craftworker.totalTimesheets === 1 ? '' : 's'} | ${craftworker.totalHours} hrs`}
                          </span>
                        </div>
                      </button>
                  </li>
              ))}
            </ul>
          </div>
        </>
    );
}